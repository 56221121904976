import React from 'react';
import './App.css';
import './Comfortaa/comfortaa.css'
import './Quicksand/quicksand.css'
import styled from 'styled-components'
import { BrowserRouter, Route } from 'react-router-dom'

import FirstPage from './components/FirstPage'
import Roxane from './components/Roxane'
import Julie from './components/Julie'

function App() {
  return (
    <Container className="App">
      <BrowserRouter>
        <Route exact path="/">
          <FirstPage/>
        </Route>
        <Route path="/roxane">
          <Roxane/>
        </Route>
        <Route path="/julie">
          <Julie/>
        </Route>
      </BrowserRouter>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`

export default App;
