import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './Comfortaa/comfortaa.css'
import './Comfortaa/Comfortaa-Regular.ttf'
import './Comfortaa/Comfortaa-Bold.ttf'
import './Comfortaa/Comfortaa-Light.ttf'

import './Quicksand/quicksand.css'
import './Quicksand/Quicksand_Book.otf'
import './Quicksand/Quicksand_Book_Oblique.otf'
import './Quicksand/Quicksand_Bold.otf'
import './Quicksand/Quicksand_Bold_Oblique.otf'
import './Quicksand/Quicksand_Light.otf'
import './Quicksand/Quicksand_Light_Oblique.otf'


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
