import React from 'react';
import styled from 'styled-components'

function Event({ event }) {
    return (
      <EventContainer>
        <Title>{event.title}</Title>
        <Sub>{event.desc}</Sub>
      </EventContainer>
    )
}

const EventContainer = styled.div`
    margin: 0;
    padding: 0;
`

const Title = styled.h3`
    font-size: 1em;
    font-weight: bold;
    padding: 0;
    margin: 0;
`

const Sub = styled.h4`
    font-size: 1em;
    font-weight: normal;
    padding: 0;
    margin: 0;
`

export default Event;