import React, { useState } from 'react';
import styled from 'styled-components'
import { Calendar, momentLocalizer   } from 'react-big-calendar'
import moment from 'moment'
import julie from '../assets/julie.json'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment_config from '../assets/moment_config'
import { debut_sem, fin_sem, feries, vacances } from '../assets/vacances'
import Event from './Event'

/**
 * 19 oct -> jeudi A
 * 10 nov -> mercredi B
 */



moment.locale('fr', moment_config);
const localizer = momentLocalizer(moment)

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const isSemaineA = (date) => {
    const origin = new Date(2020,8,13)
    return ((date.getTime()-origin.getTime())/604800000)%2 < 1 ? true : false
}

const getDebutSemaine = (date) => {
    let begin_date = new Date()
    begin_date.setHours(0)
    begin_date.setMinutes(0)
    begin_date.setSeconds(0)
    begin_date.setMilliseconds(0)
    begin_date.setYear(date.getFullYear())
    begin_date.setMonth(date.getMonth())
    if(date.getDay() === 0)
        begin_date.setDate(date.getDate() - 7)
    else 
        begin_date.setDate(date.getDate() - date.getDay())
    return begin_date
}

const isDateVacance = (date) => {
    if(date.getTime() < debut_sem.getTime() || date.getTime() > fin_sem.getTime())
        return true
    for(let i = 0; i<vacances.length; i++){
        if((vacances[i][0].getTime() <= date.getTime() && date.getTime() <= vacances[i][1].getTime()))
            return true
    }
    for(let i = 0; i < feries.length; i++){
        if(date.getFullYear() === feries[i].getFullYear() && date.getMonth() === feries[i].getMonth() && date.getDate() === feries[i].getDate())
            return true
    }
    return false
}

const generateEvents = (date) => {
    const begin_date = getDebutSemaine(date)
    const isA = isSemaineA(begin_date)
    let isVacances = false
    const week_edt = julie.creneaux.map((creneau, key) => {
        isVacances = false
        let description = `${creneau.type}${creneau.salle ? " "+creneau.salle : ""}`
        if(creneau.presentiel){
            if(creneau.presentiel === "A")
                description = `${description}${isA ? " présentiel": " distanciel"}`
            else
                description = `${description}${isA ? " distanciel": " présentiel"}`
        }
        let creneau_date = begin_date.addDays(creneau.day_of_week)
        isVacances = isDateVacance(creneau_date)
        if(isVacances || (creneau.semaine === "A" && !isA))
            return null
        else
            return {
            id : key,
            title : creneau.nom_uv,
            desc : description,
            start : new Date(creneau_date.getFullYear(), creneau_date.getMonth(), creneau_date.getDate(), creneau.debut_heure, creneau.debut_min),
            end : new Date(creneau_date.getFullYear(), creneau_date.getMonth(), creneau_date.getDate(), creneau.fin_heure, creneau.fin_min)
        }
    });
    return week_edt
}

function Julie(){
    const [date, setDate] = useState(new Date());
    return(
        <Container>
            <Title>{julie.nom} - {julie.semestre}</Title>
            <Semaine>Semaine {isSemaineA(getDebutSemaine(date)) ? "A":"B"}</Semaine>
            <Calendar
                events={generateEvents(date)}
                culture={"fr"}
                views={['week', 'day']}
                defaultView={'week'}
                localizer={localizer}
                step={30}
                min={
                    new Date(
                      date.getFullYear(), 
                      date.getMonth(), 
                      date.getDate(), 
                      6
                    )
                }
                max={
                    new Date(
                      date.getFullYear(), 
                      date.getMonth(), 
                      date.getDate(), 
                      20
                    )
                }
                components={{
                    event: Event,
                }}
                onNavigate={(e) => setDate(e)}
            />
        </Container>
    )
}

const Title = styled.h1`
  font-family: "quicksand";
  text-align: center;
`

const Semaine = styled.p`
    font-weight: bold;
`

const Container = styled.div`
    margin: 0 20%;
    padding: 4% 0;

    @media(max-width: 800px){
        margin: 0 2%;
        padding: 1% 0;
    }
`


export default Julie