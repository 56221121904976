import React from 'react';
import styled from 'styled-components'
// import bgi from '../page_1_bgi.png'

function FirstPage(){
    return(
        <Container>
            <Title>C'est mon site hi hi</Title>
            <Subtitle>Bon y a rien dessus mais c'est mon site quand même.</Subtitle>
        </Container>
    )
}

const Title = styled.h1`
  font-family: "quicksand";
  text-align: center;
`

const Subtitle = styled.h2`
  font-family: "quicksand";  
  text-align: center;
`

const Container = styled.div`
`

// const Container = styled.div`
//     position : relative;
//     height: 100vh;
//     width: 100vw;
//     background-image: url(${bgi});
//     background-position: 50% 50%;
//     background-size: 100% 100%;
// `

// const BackgroundImg = styled.img``

export default FirstPage